import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonType } from '../../../components/shared/form-control/Button';
import { PasswordInput } from '../../../components/shared/form-control/PasswordInput';
import { Heading, HeadingSize } from '../../../components/shared/text/Heading';
import AuthService from '../../../services/AuthService';
import PasswordValidator from '../common/PasswordValidator';
import { FlowProps } from './RegisterFlow';

const SetPassword: FC<FlowProps> = (props) => {
  const { next } = props;
  const [password, setPassword] = useState('');
  const [valid, setValid] = useState(false);

  const { t } = useTranslation('auth');

  const doSetPassword = useCallback(() => {
    AuthService.setPassword(password).then((res) => {
      if (res.data) {
        next();
      }
    });
  }, [next, password]);

  const detectSubmit = useCallback(
    (e: KeyboardEvent) => {
      if (valid && e.key === 'Enter') {
        doSetPassword();
      }
    },
    [doSetPassword, valid],
  );

  return (
    <>
      <Heading size={HeadingSize.H1}>{t('registration.set-password.heading')}</Heading>
      <div>{t('registration.set-password.subheading')}</div>
      <div className="w-96">
        <PasswordValidator password={password} isValid={setValid} />

        <PasswordInput
          name="password"
          id="password"
          autoFocus
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder={t('registration.set-password.inputs.password')}
          label={t('registration.set-password.inputs.password')}
          onKeyPress={detectSubmit}
          data-cy="register-password"
        />
        <div className="px-10 text-center">
          <Button className="mt-6 w-full" type={ButtonType.PRIMARY} onClick={doSetPassword} disabled={!valid} data-cy="register-next">
            <span className="font-medium">{t('registration.set-password.buttons.continue')}</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default SetPassword;
